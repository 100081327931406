import { FC } from 'react';
import { TChartLineSVGProps } from './types';

export const ChartLineSVG: FC<TChartLineSVGProps> = ({ chart, scaleItemX, scaleItemY }) => {
	return (
		<g>
			<path
				stroke="#016193"
				fill="#016193aa"
				strokeWidth={33.87}
				strokeMiterlimit={22.926}
				d={`M576 3675 ${String(
					chart.map(
						(item, index) =>
							`L${576 + 1 * scaleItemX + index * scaleItemX} ${3670 - item.y * scaleItemY}`,
					),
				)}L${576 + 1 * scaleItemX + (chart.length - 1) * scaleItemX} 3675 L576 3675`}
			/>
		</g>
	);
};
