/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { TScaleYSVGProps } from './types';

export const ScaleYSVG: FC<TScaleYSVGProps> = ({ scaleArray, scaleItem, side, color, unit }) => {
	return (
		<g>
			{/* <path
				stroke={color}
				className="prefix__fil0"
				d="M10325 140.78L10430 389.7l-6.67-3.23-6.67-3.02-6.66-2.81-6.67-2.61-6.67-2.39-6.67-2.19-6.66-1.98-6.67-1.77-6.67-1.57-6.67-1.35-6.66-1.15-6.67-.93-6.67-.73-6.67-.52-6.66-.32-6.67-.1-6.67.1-6.67.32-6.66.52-6.67.73-6.67.93-6.67 1.15-6.66 1.35-6.67 1.57-6.67 1.77-6.67 1.98-6.66 2.19-6.67 2.39-6.67 2.61-6.67 2.81-6.66 3.02-6.67 3.23 106.68-248.92zm-8.47 3533.74V265.88h16.94v3408.64H567.3z"
			/> */}

			{scaleArray.map((item) => {
				const xShift =
					side === 'left'
						? String(item).length === 1
							? 400
							: String(item).length === 2
							? 300
							: String(item).length === 3
							? 200
							: String(item).length === 4
							? 100
							: 0
						: 10400;
				return (
					<React.Fragment key={item}>
						<path
							stroke={`${color}90`}
							strokeWidth="8"
							// className="prefix__fil1 prefix__str0"
							d={`M${side === 'left' ? 530 : 580} ${3670 - item * scaleItem}h9800`}
						/>
						<text fill={color} x={xShift} y={3630 - item * scaleItem} className="prefix__fnt0">
							{item} {unit ?? ''}
						</text>
					</React.Fragment>
				);
			})}
			{side === 'left' ? (
				<>
					<path
						stroke={color}
						strokeWidth="30"
						// className="prefix__fil1 prefix__str0"
						d="M575.73 160v3534"
					/>

					<path
						// stroke={color}
						fill={color}
						d="M576 140.78L682.45 389.7l-6.67-3.23-6.67-3.02-6.66-2.81-6.67-2.61-6.67-2.39-6.67-2.19-6.66-1.98-6.67-1.77-6.67-1.57-6.67-1.35-6.66-1.15-6.67-.93-6.67-.73-6.67-.52-6.66-.32-6.67-.1-6.67.1-6.67.32-6.66.52-6.67.73-6.67.93-6.67 1.15-6.66 1.35-6.67 1.57-6.67 1.77-6.67 1.98-6.66 2.19-6.67 2.39-6.67 2.61-6.67 2.81-6.66 3.02-6.67 3.23 106.68-248.92z"
					/>
				</>
			) : (
				<>
					<path
						stroke={color}
						strokeWidth="30"
						// className="prefix__fil1 prefix__str0"
						d="M10325 160v3534"
					/>
					<path
						// stroke={color}
						fill={color}
						// className="prefix__fil0"
						d="M10325 140.78L10430 389.7l-6.67-3.23-6.67-3.02-6.66-2.81-6.67-2.61-6.67-2.39-6.67-2.19-6.66-1.98-6.67-1.77-6.67-1.57-6.67-1.35-6.66-1.15-6.67-.93-6.67-.73-6.67-.52-6.66-.32-6.67-.1-6.67.1-6.67.32-6.66.52-6.67.73-6.67.93-6.67 1.15-6.66 1.35-6.67 1.57-6.67 1.77-6.67 1.98-6.66 2.19-6.67 2.39-6.67 2.61-6.67 2.81-6.66 3.02-6.67 3.23 106.68-248.92zm-8.47 3533.74V265.88h16.94v3408.64H567.3z"
					/>
				</>
			)}
		</g>
	);
};
