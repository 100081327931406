import { FC } from "react";

export const GetAmount: FC<{ amount: number }> = ({ amount }) => {
  const amountFloor = String(Math.round(amount * 100));
  return (
    <>
      {amountFloor.slice(0, -2)}.<small>{amountFloor.slice(-2)}</small>{" "}
    </>
  );
};
