import { FC, useEffect, useState } from "react";
import ClientChartSvg from "./ClientChartSvg";
import { TableBoxHeader } from "../TableBoxHeader";
import {
  IClientsCountChartItem,
  IClientsCountProps,
  IClientsInfo,
} from "./types";
import { Spin } from "antd";

export const ClientsCount: FC<IClientsCountProps> = (props) => {
  const [clientsCount, setClientsCount] = useState<IClientsInfo>();
  const [clientsCountChart, setClientsCountChart] =
    useState<IClientsCountChartItem[]>();

  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    return (await props.fetchData()) || [];
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setClientsCount(data.clientsInfo);
      setClientsCountChart(data.clientsCountChart);
      setLoading(false);
    });
  }, []);

  return (
    <div className="andrii-table-box">
      <TableBoxHeader
        title="Clients Count"
        text="active clients - clients with sales in the last 30 days"
      />
      {loading ? (
        <Spin style={{ alignContent: "center" }} />
      ) : (
        <div className="andrii-table-box--body">
          <div className="andrii-table">
            <div
              className="andrii-table-header"
              style={{
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div className="andrii-table-br andrii-table-header--item  andrii-table-header--1item">
                Today
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                Last 7 Days
              </div>

              <div className="andrii-table-br andrii-table-header--item">
                Last Month
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                Total
              </div>
              <div className="andrii-table-header--item">Active Clients</div>
            </div>
            <div
              className="andrii-table-bt andrii-table-bb andrii-table-body"
              style={{
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div className="andrii-table-br">{clientsCount?.today ?? ""}</div>
              <div className="andrii-table-br">
                {clientsCount?.last7Days ?? ""}
              </div>
              <div className="andrii-table-br">
                {clientsCount?.lastMonth ?? ""}
              </div>
              <div className="andrii-table-br">{clientsCount?.total ?? ""}</div>
              <div>{clientsCount?.effectiveClients ?? ""}</div>
            </div>
            <div style={{ height: "100%", width: "100%", padding: "1px 10px" }}>
              <ClientChartSvg chart={clientsCountChart} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
