import { FC, useEffect, useState } from "react";
import ClientChartSvg from "./ClientChartSvg";
import { TableBoxHeader } from "../TableBoxHeader";
import { OrderCountPerDayInfo, OrderCountPerDayProps } from "./types";
import { Spin } from "antd";

export const OrderCountPerDay: FC<OrderCountPerDayProps> = (props) => {
  const [chart, setChart] = useState<OrderCountPerDayInfo[]>([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    return (await props.fetchData()) || [];
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setChart(data);
      setLoading(false);
    });
  }, []);
  return (
    <div className="andrii-table-box">
      <TableBoxHeader
        title="Order Count Per Day"
        text={`pay orders count for the previous ${chart.length} days`}
      />
      {loading ? (
        <Spin style={{ alignContent: "center" }} />
      ) : (
        <div className="andrii-table-box--body">
          <div className="andrii-table">
            <div style={{ height: "100%", width: "100%", padding: "1px 10px" }}>
              <ClientChartSvg chart={chart} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
