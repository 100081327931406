/* eslint-disable no-nested-ternary */
import { MEASURE_X, MEASURE_Y } from "components/ClientsAndSalesPage/constants";
import { FC, memo, RefObject, useRef, useState } from "react";
import { ChartLineSVG } from "./ChartLineSVG";
import { ScaleXSVG } from "./ScaleXSVG";
import { ScaleYSVG } from "./ScaleYSVG";
import { TClientChartSvgProps } from "./types";

const ClientChartSvg: FC<TClientChartSvgProps> = ({ chart }) => {
  const svgRef = useRef() as RefObject<SVGSVGElement>;
  const arrayX = chart.map((item) => item.x);

  const arrayY1 = chart.map((item) => item.y1);
  const maxY1 = Math.max.apply(null, arrayY1);
  const maxY1D = Math.ceil(maxY1 + 0.1 * maxY1);
  const razrY1 = Math.ceil(maxY1D).toString().length - 1;
  const scaleY1Tmp = Math.floor(maxY1D / 10 ** razrY1) * 10 ** razrY1;
  const scaleY1 =
    ((scaleY1Tmp - maxY1) * 100) / scaleY1Tmp > -25
      ? scaleY1Tmp
      : Math.ceil(maxY1D / 10 ** razrY1) * 10 ** razrY1;

  const hrazrY1 = +Math.ceil(scaleY1).toString()[0];
  const mrazrY1 = scaleY1 / hrazrY1;
  const scaleArrayY1: number[] = [];
  const scaleCoefficientY1 =
    +String(scaleY1)[0] < 3 ? 4 : +String(scaleY1)[0] < 7 ? 2 : 1;
  for (let i = 0; i < hrazrY1 * scaleCoefficientY1 + 1; i += 1) {
    scaleArrayY1.push((i * mrazrY1) / scaleCoefficientY1);
  }

  //= ===========================

  const arrayY2 = chart.map((item) => item.y2);
  const maxY2 = Math.max.apply(null, arrayY2);
  const maxY2D = Math.ceil(maxY2 + 0.1 * maxY2);
  const razrY2 = Math.ceil(maxY2D).toString().length - 1;
  const scaleY2Tmp = Math.floor(maxY2D / 10 ** razrY2) * 10 ** razrY2;
  const scaleY2 =
    ((scaleY2Tmp - maxY2) * 100) / scaleY2Tmp > -25
      ? scaleY2Tmp
      : Math.ceil(maxY2D / 10 ** razrY2) * 10 ** razrY2;

  const hrazrY2 = +Math.ceil(scaleY2).toString()[0];
  const mrazrY2 = scaleY2 / hrazrY2;
  const scaleArrayY2: number[] = [];
  const scaleCoefficientY2 =
    +String(scaleY2)[0] < 3 ? 4 : +String(scaleY2)[0] < 7 ? 2 : 1;
  for (let i = 0; i < hrazrY2 * scaleCoefficientY2 + 1; i += 1) {
    scaleArrayY2.push((i * mrazrY2) / scaleCoefficientY2);
  }

  const scaleItemX = MEASURE_X / arrayX.length;
  const scaleItemY1 = MEASURE_Y / scaleY1;
  const scaleItemY2 = MEASURE_Y / scaleY2;

  const arraySvgX = chart.map((item, index) => 576 + index * scaleItemX);
  const arraySvgY1 = chart.map((item) => 3670 - item.y1 * scaleItemY1);
  const arraySvgY2 = chart.map((item) => 3670 - item.y2 * scaleItemY2);

  const [chartIndex, setChartIndex] = useState(0);

  const svg = svgRef;
  const pt = svg.current?.createSVGPoint() as DOMPoint;
  if (pt) {
    pt.x = 0;
    pt.y = 0;
  }
  const [svgPt, setSvgPt] = useState({ x: 0, y: 0 });
  const [svgChartPt, setSvgChartPt] = useState({ x: 0, y1: 0, y2: 0 });
  const [showCursor, setShowCursor] = useState(false);

  const mouseOverHandler = (x = 0, y = 0) => {
    if (pt) {
      pt.x = x;
      pt.y = y;
    }
    // setFillColor("#016193");
    const svgP = pt?.matrixTransform(
      svg.current?.getScreenCTM()?.inverse()
    ) ?? { x: 0, y: 0 };
    setSvgPt({ x: svgP?.x ?? 0, y: svgP?.y ?? 0 });
    const ptX =
      arraySvgX.filter((cur) => cur > svgPt.x)[0] ||
      arraySvgX[arraySvgX.length - 1];
    const indexPtX = arraySvgX.indexOf(ptX);
    setChartIndex(indexPtX);
    // const ptY = chart[indexPtX].y;
    setSvgChartPt({
      x: ptX,
      y1: arraySvgY1[indexPtX],
      y2: arraySvgY2[indexPtX],
    });

    setShowCursor(
      svgP.x > 100 &&
        svgP.x < MEASURE_X &&
        svgP.y > 100 &&
        svgP.y < MEASURE_Y + 1000
    );
  };

  return (
    <svg
      id="chartSvg"
      viewBox="0 0 11000 4000"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      preserveAspectRatio="none"
      onMouseMove={(e) => mouseOverHandler(e.clientX, e.clientY)}
      ref={svgRef}
    >
      <defs>
        <style>
          {
            "@font-face{font-family:&quot;Helvetica&quot;font-variant:normal;font-style:normal;font-weight:400;src:url(#prefix__FontID0) format(svg)}.prefix__str0{stroke:#00486e1c;stroke-width:16.93;stroke-miterlimit:22.9256}.prefix__fil1{fill:none}.prefix__fil2{fill:#2b2b2a}.prefix__fil0{fill:#00486e6b;fill-rule:nonzero}.prefix__fnt0{font-weight:400;font-size:150px;font-family:&apos;Helvetica&apos;}.cursor{stroke-width:16.93;stroke-miterlimit:22.9256;fill:none}.textTooltip{font-weight:500;font-size:200px;font-family:&apos;Helvetica&apos;}"
          }
        </style>
      </defs>

      <ChartLineSVG
        chart={chart.map((item) => {
          return { x: item.x, y: item.y1 };
        })}
        scaleItemX={scaleItemX}
        scaleItemY={scaleItemY1}
        scaleArrayY={scaleArrayY1}
        color="#016193"
      />
      <ChartLineSVG
        chart={chart.map((item) => {
          return { x: item.x, y: item.y2 };
        })}
        scaleItemX={scaleItemX}
        scaleItemY={scaleItemY2}
        scaleArrayY={scaleArrayY2}
        color="#dc8d07"
      />
      <ScaleXSVG
        chart={chart.map((item) => {
          return { x: item.x, y: item.y1 };
        })}
        scaleItem={scaleItemX}
      />
      <ScaleYSVG
        scaleArray={scaleArrayY1}
        scaleItem={scaleItemY1}
        side="left"
        color="#016193"
      />
      <ScaleYSVG
        scaleArray={scaleArrayY2}
        scaleItem={scaleItemY2}
        side="right"
        color="#dc8d07"
        unit="€"
      />

      {showCursor && (
        <g>
          <path
            stroke="#666666"
            strokeWidth="20"
            strokeDasharray="80,80"
            d={`M${svgChartPt.x} 135.31v3534`}
          />
          <path
            stroke="#016193"
            strokeWidth="20"
            strokeDasharray="80,80"
            d={`M540 ${svgChartPt.y1}h${svgChartPt.x - 500}`}
          />
          <circle
            stroke="#016193"
            strokeWidth="30"
            fill="#f8f1e7"
            cx={svgChartPt.x}
            cy={svgChartPt.y1}
            r="50"
          />
          {/* <path stroke="#016193" className="cursor" d={`M${svgChartPt.x} 135.31v3534`} /> */}
          <path
            stroke="#dc8d07"
            strokeWidth="20"
            strokeDasharray="80,80"
            d={`M${svgChartPt.x} ${svgChartPt.y2}L10370 ${svgChartPt.y2}`}
          />
          <circle
            stroke="#dc8d07"
            strokeWidth="30"
            fill="#f8f1e7"
            cx={svgChartPt.x}
            cy={svgChartPt.y2}
            r="50"
          />
          <rect
            x={
              chartIndex > 2
                ? svgChartPt.x -
                  `${chart[chartIndex].x} - ${chart[chartIndex].y2} €`.length *
                    105 -
                  100
                : svgChartPt.x + 100
            }
            y={svgChartPt.y2 - 330}
            width={
              `${chart[chartIndex].x} - ${chart[chartIndex].y2} €`.length * 105
            }
            height="240"
            stroke="#dc8d07"
            fill="#dc8d07"
            rx="10"
            ry="10"
          />
          <text
            x={
              chartIndex > 2
                ? svgChartPt.x -
                  `${chart[chartIndex].x} - ${chart[chartIndex].y2} €`.length *
                    105 -
                  50
                : svgChartPt.x + 130
            }
            y={svgChartPt.y2 - 140}
            fill="#f8f1e7"
            className="textTooltip"
          >
            {`${chart[chartIndex].x} - ${chart[chartIndex].y2} €`}
          </text>
          <rect
            x={
              chartIndex > 2
                ? svgChartPt.x -
                  `${chart[chartIndex].x} - ${chart[chartIndex].y1}`.length *
                    105 -
                  100
                : svgChartPt.x + 100
            }
            y={
              Math.abs(svgChartPt.y2 - svgChartPt.y1) < 240
                ? svgChartPt.y2 - 330 - 240
                : svgChartPt.y1 - 330
            }
            width={
              `${chart[chartIndex].x} - ${chart[chartIndex].y1}`.length * 105
            }
            height="240"
            stroke="#016193"
            fill="#016193"
            rx="10"
            ry="10"
          />
          <text
            x={
              chartIndex > 2
                ? svgChartPt.x -
                  `${chart[chartIndex].x} - ${chart[chartIndex].y1}`.length *
                    105 -
                  50
                : svgChartPt.x + 130
            }
            y={
              Math.abs(svgChartPt.y2 - svgChartPt.y1) < 240
                ? svgChartPt.y2 - 140 - 240
                : svgChartPt.y1 - 140
            }
            fill="#f8f1e7"
            className="textTooltip"
          >
            {`${chart[chartIndex].x} - ${chart[chartIndex].y1}`}
          </text>
        </g>
      )}
    </svg>
  );
};

const MemoClientChartSvg = memo(ClientChartSvg);
export default MemoClientChartSvg;
