import { FC } from "react";
import { OperatorFeSalesInformation } from "./OperatorFeSalesInformation";
import "./index.css";
import "./andrii-table.css";
import { ClientsSalesInformation } from "./ClientsSalesInformation";
import { TopClients } from "./TopClients";
import { ClientsCount } from "./ClientsCount";
import { OrderCountPerDay } from "./OrderCountPerDay";
import { TClientsAndSalesPageProps } from "./types";
// import { OperatorFeSalesInformation } from "@q4us-sw/eas-ui";

export const ClientsAndSalesPage: FC<TClientsAndSalesPageProps> = ({
  salesInformation,
  clientsCount,
  clientsCountChart,
  clientsSalesInformation,
  orderCountChart,
  topClients,
}) => {
  const loadTopClients = async () => {
    return topClients || [];
  };
  const loadClientsSalesInformation = async () => {
    return clientsSalesInformation || [];
  };
  const loadSalesInformation = async () => {
    return salesInformation || [];
  };
  const clientsCountAndChart = async () => {
    const clientsCount1 = clientsCount;
    const clientsCountChart1 = clientsCountChart;
    return {
      clientsInfo: clientsCount1,
      clientsCountChart: clientsCountChart1,
    };
  };
  const loadOrderCountChart = async () => {
    return orderCountChart || [];
  };

  return (
    <div className="eas-taxes-end-sales--main">
      <OperatorFeSalesInformation fetchData={loadSalesInformation} />
      <ClientsSalesInformation fetchData={loadClientsSalesInformation} />
      <TopClients fetchData={loadTopClients} />
      <ClientsCount fetchData={clientsCountAndChart} />
      <OrderCountPerDay fetchData={loadOrderCountChart} />
    </div>
  );
};
