import { FC, useEffect, useState } from "react";
import { MONTH_ARRAY } from "../constants";
import { TableBoxHeader } from "../TableBoxHeader";
import { IClientsSalesInfo, IClientsSalesInfoProps } from "./types";
import { Spin } from "antd";
import { GetAmount } from "../GetAmount";

export const ClientsSalesInformation: FC<IClientsSalesInfoProps> = (props) => {
  const [clientsSalesInformation, setClientsSalesInformation] = useState<
    IClientsSalesInfo[]
  >([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    return (await props.fetchData()) || [];
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setClientsSalesInformation(data);
      setLoading(false);
    });
  }, []);

  const dateToday = new Date();
  const prevMonth = (currenMonth: number, num: number) => {
    if (currenMonth - num < 0) return 12 - currenMonth - num;
    return currenMonth - num;
  };

  const getAmount = (amount: number) => {
    const amountFloor = String(Math.round(amount * 100));
    return (
      <>
        {amountFloor.slice(0, -2)}.<small>{amountFloor.slice(-2)}</small>{" "}
      </>
    );
  };

  return (
    <div className="andrii-table-box">
      <TableBoxHeader
        title="Clients Sales Information"
        text="total order amount in EUR"
      />
      {loading ? (
        <Spin style={{ alignContent: "center" }} />
      ) : (
        <div className="andrii-table-box--body">
          <div className="andrii-table">
            <div
              className="andrii-table-header"
              style={{
                gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div className="andrii-table-br andrii-table-header--item" />
              <div className="andrii-table-br andrii-table-header--item">
                {MONTH_ARRAY[prevMonth(dateToday.getUTCMonth(), 3)]}
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                {MONTH_ARRAY[prevMonth(dateToday.getUTCMonth(), 2)]}
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                {MONTH_ARRAY[prevMonth(dateToday.getUTCMonth(), 1)]}
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                {MONTH_ARRAY[dateToday.getUTCMonth()]}{" "}
                <small>(Current Month)</small>
              </div>
              <div className="andrii-table-header--item">Overall</div>
            </div>
            {clientsSalesInformation.map((item) => (
              <div
                key={item.name}
                className="andrii-table-bt andrii-table-body"
                style={{
                  gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="andrii-table-br andrii-table-body--1item">
                  {item.name}
                </div>
                <div className="andrii-table-br andrii-table-amount">
                  <GetAmount amount={item.currentMonthMinus3} />
                </div>
                <div className="andrii-table-br andrii-table-amount">
                  <GetAmount amount={item.currentMonthMinus2} />
                </div>
                <div className="andrii-table-br andrii-table-amount">
                  <GetAmount amount={item.currentMonthMinus1} />
                </div>
                <div className="andrii-table-br andrii-table-amount">
                  <GetAmount amount={item.currentMonth} />
                </div>
                <div className="andrii-table-amount">
                  <GetAmount amount={item.overall} />
                </div>
              </div>
            ))}
            <div
              className="andrii-table-bt andrii-table-total 
						andrii-table-body
						andrii-table-bb"
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr 1fr",
                textAlign: "right",
                fontSize: "13px",
              }}
            >
              <div className="andrii-table-br andrii-table-body--1item">
                Total
              </div>
              <div className="andrii-table-br andrii-table-amount">
                {getAmount(
                  clientsSalesInformation.reduce(
                    (acc, curr) => acc + curr.currentMonthMinus3,
                    0
                  )
                )}
              </div>
              <div className="andrii-table-br andrii-table-amount">
                {getAmount(
                  clientsSalesInformation.reduce(
                    (acc, curr) => acc + curr.currentMonthMinus2,
                    0
                  )
                )}
              </div>
              <div className="andrii-table-br andrii-table-amount">
                {getAmount(
                  clientsSalesInformation.reduce(
                    (acc, curr) => acc + curr.currentMonthMinus1,
                    0
                  )
                )}
              </div>
              <div className="andrii-table-br andrii-table-amount">
                {getAmount(
                  clientsSalesInformation.reduce(
                    (acc, curr) => acc + curr.currentMonth,
                    0
                  )
                )}
              </div>
              <div className="andrii-table-amount">
                {getAmount(
                  clientsSalesInformation.reduce(
                    (acc, curr) => acc + curr.overall,
                    0
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
