import { FC } from "react";
import { TChartLineSVGProps } from "./types";

export const ChartLineSVG: FC<TChartLineSVGProps> = ({
  chart,
  scaleItemX,
  scaleItemY,
  color,
}) => {
  return (
    <g>
      <path
        stroke={`${color}cc`}
        fill={`${color}55`}
        strokeWidth={33.87}
        strokeMiterlimit={22.926}
        d={`M576 3675 ${String(
          chart.map(
            (item, index) =>
              `L${576 + index * scaleItemX} ${3670 - item.y * scaleItemY}`
          )
        )}L${576 + (chart.length - 1) * scaleItemX} 3675 L576 3675`}
      />
    </g>
  );
};
