import { FC, useEffect, useState } from "react";
import { TableBoxHeader } from "../TableBoxHeader";
import { TopClientsInfo, TopClientsProps } from "./types";
import { GetAmount } from "../GetAmount";
import { Spin } from "antd";

export const TopClients: FC<TopClientsProps> = (props) => {
  const [topClients, setTopClients] = useState<TopClientsInfo[]>([]);
  const [loading, setLoading] = useState(true);
  let maxCount: number, maxAmount: number;

  const loadData = async () => {
    return (await props.fetchData()) || [];
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setTopClients(data);
      setLoading(false);
    });
  }, []);

  if (topClients.length > 0) {
    maxAmount = topClients.reduce((acc, curr) =>
      Number(acc.orderAmount) > Number(curr.orderAmount) ? acc : curr
    ).orderAmount;
    maxCount = topClients.reduce((acc, curr) =>
      Number(acc.orderCount) > Number(curr.orderCount) ? acc : curr
    ).orderCount;
  }
  const procAmount = (num: number) => (num * 100) / maxAmount;
  const procCount = (num: number) => (num * 100) / maxCount;

  return (
    <div className="andrii-table-box">
      <TableBoxHeader
        title="Top Clients"
        text="order information last 30 days"
      />
      {loading ? (
        <Spin style={{ alignContent: "center" }} />
      ) : (
        <div className="andrii-table-box--body">
          <div className="andrii-table">
            <div
              className="andrii-table-header andrii-table-bb"
              style={{
                gridTemplateColumns: "2fr 1fr 1fr 1fr",
              }}
            >
              <div className="andrii-table-br andrii-table-header--item">
                Client Name
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                Country
              </div>
              <div className="andrii-table-br andrii-table-header--item">
                Order Amount, EUR
              </div>
              <div className="andrii-table-header--item">Order Count</div>
            </div>
            {topClients.map((item) => (
              <div
                key={item.name}
                className="andrii-table-body andrii-table-bb"
                style={{
                  gridTemplateColumns: "2fr 1fr 1fr 1fr",
                }}
              >
                <div className="andrii-table-br andrii-table-body--1item">
                  {item.name}
                </div>
                <div className="andrii-table-br">{item.country}</div>
                <div
                  className="andrii-table-br andrii-table-amount"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background:
                        "linear-gradient(90deg, #01619380 0%, #01619380 100%)",
                      height: "100%",
                      width: `${procAmount(item.orderAmount)}%`,
                      // borderRight: '1px solid #cae3ff',
                      borderBottom: "1px solid #0062935c",
                      zIndex: 1000,
                    }}
                  />
                  <span style={{ zIndex: 1001, position: "relative" }}>
                    <GetAmount amount={item.orderAmount} />
                  </span>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="andrii-table-count"
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background:
                        "linear-gradient(90deg, #49ad1880 0%, #49ad1880 100%)",
                      height: "100%",
                      width: `${procCount(item.orderCount)}%`,
                      borderRight: "1px solid #d1fbbd",
                      zIndex: 1000,
                    }}
                  />
                  <span style={{ zIndex: 1001, position: "relative" }}>
                    {item.orderCount}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
