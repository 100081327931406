import { useState } from "react";
import { THandleChange, TInputProps } from "./types";

export const useRadioButtons = (name: string, defaultValue: string ): THandleChange => {
  const [value, setState] = useState<string>(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };

  const inputProps: TInputProps = {
    name,
    type: "radio",
    onChange: handleChange,
  };

  return [value, inputProps];
};
