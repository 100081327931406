import React, { FC } from 'react';
import { TScaleXSVGProps } from './types';

export const ScaleXSVG: FC<TScaleXSVGProps> = ({ chart, scaleItem }) => {
	return (
		<g>
			{chart.map((item, index) => (
				<React.Fragment key={item.x}>
					<path
						className="prefix__fil1 prefix__str0"
						d={`M${576 + index * scaleItem} 135.31v3534`}
					/>
					{(chart.length < 12 || (chart.length >= 12 && index % 2 === 0)) && (
						<text x={576 + index * scaleItem} y={3960} className="prefix__fil2 prefix__fnt0">
							{item.x}
						</text>
					)}{' '}
				</React.Fragment>
			))}
			<path
				className="prefix__fil0"
				d="M10800 3682.97m125.1-8.47l-248.92 106.68 3.23-6.67 3.02-6.66 2.81-6.67 2.61-6.67 2.39-6.67 2.19-6.66 1.98-6.67 1.77-6.67 1.57-6.67 1.35-6.66 1.15-6.67.93-6.67.73-6.67.52-6.66.32-6.67.1-6.67-.1-6.67-.32-6.67-.52-6.66-.73-6.67-.93-6.67-1.15-6.67-1.35-6.66-1.57-6.67-1.77-6.67-1.98-6.67-2.19-6.66-2.39-6.67-2.61-6.67-2.81-6.67-3.02-6.66-3.23-6.67 248.92 106.68z"
			/>
			<path stroke="#a3a3a3" strokeWidth="30" d="M10800 3675H575.73z" />
		</g>
	);
};
