/* eslint-disable no-nested-ternary */
import { MEASURE_X, MEASURE_Y } from "components/ClientsAndSalesPage/constants";
import { FC, memo, RefObject, useRef, useState } from "react";
import { ChartLineSVG } from "./ChartLineSVG";
import { ScaleXSVG } from "./ScaleXSVG";
import { ScaleYSVG } from "./ScaleYSVG";
import { TClientChartSvgProps } from "./types";

const ClientChartSvg: FC<TClientChartSvgProps> = ({ chart }) => {
  const svgRef = useRef() as RefObject<SVGSVGElement>;
  const [chartIndex, setChartIndex] = useState(0);
  const [svgPt, setSvgPt] = useState({ x: 0, y: 0 });
  const [svgChartPt, setSvgChartPt] = useState({ x: 0, y: 0 });
  const [showCursor, setShowCursor] = useState(false);


  if (!chart?.length) return null;
  const arrayX = chart.map((item) => item.x);
  const arrayY = chart.map((item) => item.y);
  const maxY = Math.max.apply(null, arrayY);
  const maxYD = Math.ceil(maxY + 0.1 * maxY);
  const razrY = Math.ceil(maxYD).toString().length - 1;
  const scaleYTmp = Math.floor(maxYD / 10 ** razrY) * 10 ** razrY;
  const scaleY =
    ((scaleYTmp - maxY) * 100) / scaleYTmp > -25
      ? scaleYTmp
      : Math.ceil(maxYD / 10 ** razrY) * 10 ** razrY;

  const hRazrY = +Math.ceil(scaleY).toString()[0];
  const mRazrY = scaleY / hRazrY;
  const scaleArrayY: number[] = [];
  const scaleCoefficient =
    +String(scaleY)[0] < 3 ? 4 : +String(scaleY)[0] < 7 ? 2 : 1;
  for (let i = 0; i < hRazrY * scaleCoefficient + 1; i += 1) {
    scaleArrayY.push((i * mRazrY) / scaleCoefficient);
  }

  const scaleItemX = MEASURE_X / arrayX.length;
  const scaleItemY = MEASURE_Y / scaleY;

  const arraySvgX = chart.map(
    (item, index) => 576 + 1 * scaleItemX + index * scaleItemX
  );
  const arraySvgY = chart.map((item) => 3670 - item.y * scaleItemY);


  const svg = svgRef;
  const pt = svg.current?.createSVGPoint() as DOMPoint;
  if (pt) {
    pt.x = 0;
    pt.y = 0;
  }

  const mouseOverHandler = (x = 0, y = 0) => {
    if (pt) {
      pt.x = x;
      pt.y = y;
    }
    // setFillColor("#016193");
    const svgP = pt?.matrixTransform(
      svg.current?.getScreenCTM()?.inverse()
    ) ?? { x: 0, y: 0 };
    setSvgPt({ x: svgP?.x ?? 0, y: svgP?.y ?? 0 });
    const ptX =
      arraySvgX.filter((cur) => cur > svgPt.x)[0] ||
      arraySvgX[arraySvgX.length - 1];
    const indexPtX = arraySvgX.indexOf(ptX);
    setChartIndex(indexPtX);
    // const ptY = chart[indexPtX].y;
    setSvgChartPt({ x: ptX, y: arraySvgY[indexPtX] });

    setShowCursor(
      svgP.x > 100 &&
        svgP.x < MEASURE_X + 1000 &&
        svgP.y > 100 &&
        svgP.y < MEASURE_Y + 1000
    );
  };

  return (
    <svg
      id="chartSvg"
      viewBox="0 0 12000 4000"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      preserveAspectRatio="none"
      onMouseMove={(e) => mouseOverHandler(e.clientX, e.clientY)}
      ref={svgRef}
    >
      <defs>
        <style>
          {
            "@font-face{font-family:&quot;Helvetica&quot;font-variant:normal;font-style:normal;font-weight:400;src:url(#prefix__FontID0) format(svg)}.prefix__str0{stroke:#00486e1c;stroke-width:16.93;stroke-miterlimit:22.9256}.prefix__fil1{fill:none}.prefix__fil2{fill:#00486e6b}.prefix__fil0{fill:#00486e6b;fill-rule:nonzero}.prefix__fnt0{font-weight:400;font-size:150px;font-family:&apos;Helvetica&apos;}.cursor{stroke:#016193;stroke-width:20;stroke-miterlimit:22.9256;fill:none}.textTooltip{font-weight:500;font-size:200px;font-family:&apos;Helvetica&apos;}"
          }
        </style>
      </defs>
      <ScaleXSVG chart={chart} scaleItem={scaleItemX} />
      <ScaleYSVG scaleArray={scaleArrayY} scaleItem={scaleItemY} />
      <ChartLineSVG
        chart={chart}
        scaleItemX={scaleItemX}
        scaleItemY={scaleItemY}
        scaleArrayY={scaleArrayY}
      />
      {showCursor && (
        <g>
          <path
            stroke="#016193"
            strokeDasharray="80,80"
            className="cursor"
            d={`M${svgChartPt.x} 135.31v3534`}
          />
          <path
            stroke="#016193"
            strokeDasharray="80,80"
            className="cursor"
            d={`M575.73 ${svgChartPt.y}h11133.66`}
          />
          <circle
            stroke="#016193"
            strokeWidth="30"
            fill="#f8f1e7"
            cx={svgChartPt.x}
            cy={svgChartPt.y}
            r="50"
          />
          <rect
            x={chartIndex > 1 ? svgChartPt.x - 1500 : svgChartPt.x + 100}
            y={svgChartPt.y - 330}
            width={String(chart[chartIndex].y).length * 100 + 1000}
            height="240"
            stroke="#016193"
            fill="#016193"
            rx="10"
            ry="10"
          />
          <text
            x={chartIndex > 1 ? svgChartPt.x - 1430 : svgChartPt.x + 130}
            y={svgChartPt.y - 140}
            fill="#f8f1e7"
            className="textTooltip"
          >
            {`${chart[chartIndex].x} - ${chart[chartIndex].y}`}
          </text>
        </g>
      )}
    </svg>
  );
};

const MemoClientChartSvg = memo(ClientChartSvg);
export default MemoClientChartSvg;
