import { FC, useEffect, useState } from "react";
import { TableBoxHeader } from "../TableBoxHeader";
import { useRadioButtons } from "./useRadioButtons";
import { SalesInfo, SalesInfoProps } from "./types";
import { GetAmount } from "../GetAmount";
import { Spin } from "antd";

const DATE_RANGE_VALUE_CURRENT = "Current";
const DATE_RANGE_VALUE_PREVIOUS = "Previous";

export const OperatorFeSalesInformation: FC<SalesInfoProps> = (props) => {
  const [dateRangeValue, dateRangeInputProps] = useRadioButtons(
    "dateRange",
    DATE_RANGE_VALUE_CURRENT
  );
  const [salesInformation, setSalesInformation] = useState<SalesInfo[]>([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    return (await props.fetchData()) || [];
  };

  useEffect(() => {
    setLoading(true);
    loadData().then((data) => {
      setSalesInformation(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="andrii-table-box">
      <TableBoxHeader title="Sales Information">
        <div className="andrii-table-box--radio-input">
          <input
            id="andrii-table-box--radio1"
            value={DATE_RANGE_VALUE_CURRENT}
            checked={dateRangeValue === DATE_RANGE_VALUE_CURRENT}
            {...dateRangeInputProps}
          />{" "}
          <label htmlFor="andrii-table-box--radio1">Current Month</label>{" "}
          <input
            id="andrii-table-box--radio2"
            value={DATE_RANGE_VALUE_PREVIOUS}
            checked={dateRangeValue === DATE_RANGE_VALUE_PREVIOUS}
            {...dateRangeInputProps}
          />{" "}
          <label htmlFor="andrii-table-box--radio2">Previous Month</label>
        </div>
      </TableBoxHeader>
      {loading ? (
        <Spin style={{ alignContent: "center" }} />
      ) : (
        <div className="andrii-table-box--body">
          <div className="andrii-table">
            <div
              className="andrii-table-header"
              style={{
                gridTemplateColumns: "1.5fr 3fr 1fr",
              }}
            >
              <div className="andrii-table-br" />
              <div className="andrii-table-br">
                <div>Order Count</div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit,minmax(50px, 1fr))",
                    borderTop: "1px solid #00486e6b",
                  }}
                >
                  <div className="andrii-table-br">Today</div>
                  <div className="andrii-table-br">Last 7 Days</div>
                  <div>{dateRangeValue} Month</div>
                </div>
              </div>
              <div>
                <div>EAS Fee, EUR</div>
                <div className="andrii-table-bt">{dateRangeValue} Month</div>
              </div>
            </div>
            {salesInformation.map((item) => (
              <div
                key={item.name}
                className="andrii-table-body andrii-table-bt"
                style={{
                  gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="andrii-table-br andrii-table-body--1item">
                  {item.name}
                </div>
                <div className="andrii-table-br andrii-table-count">
                  {item.orderCount.today}
                </div>
                <div className="andrii-table-br andrii-table-count">
                  {item.orderCount.last7Day}
                </div>
                <div className="andrii-table-br andrii-table-count">
                  {dateRangeValue === DATE_RANGE_VALUE_CURRENT
                    ? item.orderCount.currentMonth
                    : item.orderCount.previousMonth}
                </div>
                <div className="andrii-table-amount">
                  <GetAmount
                    amount={
                      dateRangeValue === DATE_RANGE_VALUE_CURRENT
                        ? item.EASFee.currentMonth
                        : item.EASFee.previousMonth
                    }
                  />
                </div>
              </div>
            ))}
            <div
              className="andrii-table-body andrii-table-bt andrii-table-total andrii-table-bb"
              style={{
                gridTemplateColumns: "1.5fr 1fr 1fr 1fr 1fr",
              }}
            >
              <div className="andrii-table-br andrii-table-body--1item">
                Total
              </div>
              <div className="andrii-table-br andrii-table-count">
                {salesInformation.reduce(
                  (acc, curr) => acc + curr.orderCount.today,
                  0
                )}
              </div>
              <div className="andrii-table-br andrii-table-count">
                {salesInformation.reduce(
                  (acc, curr) => acc + curr.orderCount.last7Day,
                  0
                )}
              </div>
              <div className="andrii-table-br andrii-table-count">
                {dateRangeValue === DATE_RANGE_VALUE_CURRENT
                  ? salesInformation.reduce(
                      (acc, curr) => acc + curr.orderCount.currentMonth,
                      0
                    )
                  : salesInformation.reduce(
                      (acc, curr) => acc + curr.orderCount.previousMonth,
                      0
                    )}
              </div>
              <div className="andrii-table-amount">
                <GetAmount
                  amount={
                    dateRangeValue === DATE_RANGE_VALUE_CURRENT
                      ? salesInformation.reduce(
                          (acc, curr) => acc + curr.EASFee.currentMonth,
                          0
                        )
                      : salesInformation.reduce(
                          (acc, curr) => acc + curr.EASFee.previousMonth,
                          0
                        )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
