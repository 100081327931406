import { FC } from "react";
import "./App.css";
import { ClientsAndSalesPage } from "components/ClientsAndSalesPage";
import "antd/dist/antd.css";
import salesInformation from "./api/salesInformation.json";
import clientsCount from "./api/clientsCount.json";
import clientsSalesInformation from "./api/clientsSalesInformation.json";
import orderCountChart from "./api/orderCountChart.json";
import topClients from "./api/topClients.json";
import clientsCountChart from "./api/clientsCountChart.json";

const App: FC = () => {
  return (
    <div style={{ padding: "40px" }}>
      <ClientsAndSalesPage
        salesInformation={salesInformation}
        clientsCount={clientsCount}
        clientsSalesInformation={clientsSalesInformation}
        orderCountChart={orderCountChart}
        topClients={topClients}
        clientsCountChart={clientsCountChart}
      />
    </div>
  );
};

export default App;
