/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { TScaleYSVGProps } from './types';

export const ScaleYSVG: FC<TScaleYSVGProps> = ({ scaleArray, scaleItem }) => {
	return (
		<g>
			<path
				className="prefix__fil0"
				d="M576 140.78L682.45 389.7l-6.67-3.23-6.67-3.02-6.66-2.81-6.67-2.61-6.67-2.39-6.67-2.19-6.66-1.98-6.67-1.77-6.67-1.57-6.67-1.35-6.66-1.15-6.67-.93-6.67-.73-6.67-.52-6.66-.32-6.67-.1-6.67.1-6.67.32-6.66.52-6.67.73-6.67.93-6.67 1.15-6.66 1.35-6.67 1.57-6.67 1.77-6.67 1.98-6.66 2.19-6.67 2.39-6.67 2.61-6.67 2.81-6.66 3.02-6.67 3.23 106.68-248.92zm-8.47 3533.74V265.88h16.94v3408.64H567.3z"
			/>

			{scaleArray.map((item) => {
				const xShift =
					String(item).length === 1
						? 400
						: String(item).length === 2
						? 300
						: String(item).length === 3
						? 200
						: String(item).length === 4
						? 100
						: 0;
				return (
					<React.Fragment key={item}>
						<path
							className="prefix__fil1 prefix__str0"
							d={`M535 ${3670 - item * scaleItem}h11133.66`}
						/>
						<text x={xShift} y={3670 - item * scaleItem} className="prefix__fil2 prefix__fnt0">
							{item}
						</text>
					</React.Fragment>
				);
			})}
		</g>
	);
};
